import { createSlice } from "@reduxjs/toolkit";

export const recipeSlice = createSlice({
  name: "recipe",
  initialState: {
    btnLoading: false,
    loading: false,
    modal: false,
    error: {
      status: false,
      errors: [],
      message: null,
    },
    success: {
      status: false,
      message: null,
    },
    recipes: [],
    recipe: {},
  },
  reducers: {
    btnLoading: (state, action) => {
      state.btnLoading = action.payload || false;
    },
    loading: (state, action) => {
      state.loading = action.payload || false;
    },
    error: (state, action) => {
      state.error.status = action.payload.status ? action.payload.status : false;
      state.error.errors = action.payload.errors ? action.payload.errors : [];
      state.error.message = action.payload.message ? action.payload.message : null;
    },
    success: (state, action) => {
      state.success.status = action.payload.status ? action.payload.status : false;
      state.success.message = action.payload.message ? action.payload.message : null;
    },
    recipes: (state, action) => {
      state.recipes = action.payload || [];
    },
    recipe: (state, action) => {
      state.recipe = action.payload || {};
    },
  },
});

export const { btnLoading, loading, error, success, recipes, recipe } =
  recipeSlice.actions;
export default recipeSlice.reducer;
