import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../state/actions/_auth";

const style = {
  li: "text-blue-500 hover:text-blue-700 border-r px-3 border-blue-300 last:border-0",
};
export default function Menu() {
  const r_auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    !r_auth.loading && (
      <>
        <div className="flex justify-between items-center p-2 border-b">
          <div>Instructions</div>
          <ul className="flex">
            <li className={style.li}>
              <Link to="/">
                <i className="fa-solid fa-home" /> Home
              </Link>
            </li>
            {r_auth.userAuth ? (
              <>
                <li className={style.li}>
                  <Link to="/users">
                    <i className="fa-solid fa-users" /> Users
                  </Link>
                </li>
                <li className={style.li}>
                  <Link to="/recipes">
                    <i className="fa-solid fa-utensils" /> Recipes
                  </Link>
                </li>
                <li className={`${style.li} cursor-pointer`}>
                  <span onClick={() => dispatch(logoutAction())}>
                    <i className="fa-solid fa-sign-out-alt" /> Logout
                  </span>
                </li>
              </>
            ) : (
              <>
                <li className={style.li}>
                  <Link to="/login">
                    <i className="fa-solid fa-sign-in-alt" /> Login
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </>
    )
  );
}
