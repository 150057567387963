import api from "../../utils/api";
import {
  btnLoading,
  loading,
  error,
  success,
  userAuth,
} from "../reducers/r_auth";

export const verifyAuth = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get("/verify-login");
    if (response.status === 200) {
      dispatch(userAuth(true));
    }
  } catch (err) {
    console.error(err);
    dispatch(userAuth(false));
  } finally {
    dispatch(loading());
  }
};

export const loginAction = (data) => async (dispatch) => {
  // console.log("Data ", data)
  dispatch(btnLoading(true));
  try {
    const info = {
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT,
      client_secret: process.env.REACT_APP_SECRET,
      username: data.username,
      password: data.password,
      scope: "",
    };
    const response = await api.post(
      `${process.env.REACT_APP_URL}/oauth/token`,
      info
    );
    // console.log("Response", response);
    if (response.status === 200) {
      dispatch(userAuth(true));
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("expires_in", response.data.expires_in);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("token_type", response.data.token_type);
      window.location.href= "/";
    }
  } catch (err) {
    // console.error(err)
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const logoutAction = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    dispatch(userAuth(false));
    localStorage.removeItem("access_token");
      localStorage.removeItem("expires_in");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token_type");
      window.location.href= "/login";
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(loading());
  }
};
