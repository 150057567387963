import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "../comp/forms";
import { Submit } from "../comp/buttons";
import { loginAction } from "../../state/actions/_auth";
import { Navigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const r_auth = useSelector((state) => state.auth);
  const [data, setData] = useState({
    username: "publio.quintero@gmail.com",
    password: "adminroot01",
  });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(loginAction(data));
  };

  return (
    <>
			{r_auth.userAuth && <Navigate to="/" />}
      <form
        action=""
        className="w-1/3 mx-auto bg-gray-100 p-5 my-10 border-r-4 border-b-4"
        onSubmit={handleSubmit}
      >
        <TextField
          type="text"
          id="username"
          value={data.username}
          className=""
          onChange={handleChange}
          error={r_auth.error}
          label="Username"
        />
        <TextField
          type="password"
          id="password"
          value={data.username}
          className=""
          onChange={handleChange}
          error={r_auth.error}
          label="Password"
        />
        <div className="flex justify-center items-center">
          <Submit
            type="submit"
            status={r_auth.btnLoading}
            text="Log In"
            icon="fa-solid fa-sign-in-alt"
          />
        </div>
      </form>
    </>
  );
}
