import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function RequiredAuth({ children }) {
  const r_auth = useSelector((state) => state.auth);

  console.log(r_auth.userAuth);
  return (
    r_auth.userAuth !== undefined && (
      <>
        {r_auth.userAuth ? children : <Navigate to="/login" />}
      </>
    )
  );
}
