import { TextField } from "../comp/forms";
import { Submit } from "../comp/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { storeRecipes } from "../../state/actions/_recipe";
import { Navigate } from "react-router-dom";

export default function Create() {
  const dispatch = useDispatch();
  const r_recipe = useSelector((state) => state.recipe);
  const [data, setData] = useState({
    name: "",
  });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let imagefile = document.querySelector("#image");
    formData.append("name", data.name || "");
    formData.append("image", imagefile.files[0]);
    dispatch(storeRecipes(formData));
  };

  return (
    <>
			{r_recipe.success.status && <Navigate to="/recipes" />}
      <form
        action=""
        onSubmit={handleSubmit}
        className="w-1/2 mx-auto my-10 bg-gray-100 p-5 border-r-4 border-b-4 border-gray-300"
      >
        <TextField
          type="text"
          id="name"
          className=""
          value={data.name}
          onChange={handleChange}
          error={r_recipe.error}
          label="Name"
        />
        <TextField
          type="file"
          id="image"
          className=""
          onChange={handleChange}
          error={r_recipe.error}
          label="Image"
        />
        <div className="flex justify-center items-center">
          <Submit
            type="submit"
            status={r_recipe.btnLoading}
            text="Create"
            icon="fa-solid fa-plus"
          />
        </div>
      </form>
    </>
  );
}
