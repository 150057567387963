import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import QRCode from "react-qr-code";
import { getRecipe } from "../../state/actions/_recipe";
import Loading from "../comp/loading";
// import { recipe } from "../../state/reducers/r_recipe";

export const Instructions = () => {
  const dispatch = useDispatch();
  let { uuid } = useParams();
  const r_recipe = useSelector((state) => state.recipe);

  useEffect(() => {
    dispatch(getRecipe(uuid));

    // return () => {
    //   dispatch(recipe({}));
    // };
  }, [dispatch, uuid]);

  // console.log(r_recipe);
  return r_recipe.loading && !r_recipe.recipe && !r_recipe.recipe.image ? (
    <Loading />
  ) : (
    <img
      src={`${process.env.REACT_APP_URL}/${r_recipe.recipe.image}`}
      alt={r_recipe.recipe.name}
      className="w-full"
    />
  );
};

export const ViewCode = () => {
  let { code } = useParams();
  return (
    <div className="flex w-screen h-screen justify-center items-center">
      <img src={`${process.env.REACT_APP_URL}/qrcodes/${code}.svg`} alt="" />
    </div>
  );
};
