import { Routes, Route } from 'react-router-dom';
import Store from './create';
import Update from './update';
import Delete from './delete';
import List from './list';

export default function Index() {
	return (
		<Routes>
			<Route path="" element={<List />} />
			<Route path="create" element={<Store />} />
			<Route path="update/:recipe_id" element={<Update />} />
			<Route path="delete/:recipe_id" element={<Delete />} />
		</Routes>
	)
}