import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipes } from "../../state/actions/_recipe";
import { Link } from "react-router-dom";
import { success } from "../../state/reducers/r_recipe";
import Image from "./image";
import QRCode from "react-qr-code";

export default function List() {
  const dispatch = useDispatch();
  const r_recipe = useSelector((state) => state.recipe);
  const [modalImage, setModalImage] = useState(false);

  useEffect(() => {
    dispatch(success({ status: false }));
    dispatch(getRecipes());
  }, [dispatch]);

  return (
    <div>
      <ul className="panel-buttons">
        <li>
          <Link to="create">
            <i className="fa-solid fa-plus mr-1" />
            Add Recipe
          </Link>
        </li>
      </ul>
      {r_recipe.recipes.length > 0 ? (
        <div className="w-11/12 mx-auto">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Code</th>
              </tr>
            </thead>
            <tbody>
              {r_recipe.recipes.map((item, key) => (
                <tr key={key}>
                  <td>{item.name}</td>
                  <td>
                    <Link
                      to={`/view-image/${item.uuid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}/${item.image}`}
                        alt=""
                        className="w-24 h-24 object-cover"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/view-code/${item.uuid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={`${process.env.REACT_APP_URL}/${item.qrcode_path}`} alt="" className="w-24 h-24" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>No records.</div>
      )}
      {modalImage && <Image closeModal={() => setModalImage(false)} />}
    </div>
  );
}
