import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Menu from '../comp/menu';
import { verifyAuth } from '../../state/actions/_auth'

export default function Container() {
	const dispatch = useDispatch();
	const r_auth = useSelector( state => state.auth );

	useEffect( () => {
		dispatch(verifyAuth());
	},[dispatch])
	
	return (
		<div>
			<Menu />
			<div><Outlet /></div>
		</div>
	);
}