import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    btnLoading: false,
    loading: false,
    error: {
      status: false,
      errors: [],
      message: null,
    },
    success: {
      status: false,
      message: null,
    },
    userAuth: undefined,
  },
  reducers: {
    btnLoading: (state, action) => {
      state.btnLoading = action.payload || false;
    },
    loading: (state, action) => {
      state.loading = action.payload || false;
    },
    error: (state, action) => {
      state.error.status = action.payload.status || false;
      state.error.errors = action.payload.errors || [];
      state.error.message = action.payload.message || null;
    },
    success: (state, action) => {
      state.success.status = action.payload.status || false;
      state.success.status = action.payload.message || null;
    },
    userAuth: (state, action) => {
      state.userAuth = action.payload || undefined;
    },
  },
});

export const { btnLoading, loading, error, success, userAuth } =
  authSlice.actions;
export default authSlice.reducer;
