import api from "../../utils/api";
import {
  btnLoading,
  loading,
  error,
  success,
  recipes,
  recipe,
} from "../reducers/r_recipe";

export const getRecipes = () => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get(`/recipe`);
    if (response.status === 200) {
      dispatch(recipes(response.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(loading());
  }
};

export const getRecipe = (recipe_uuid) => async (dispatch) => {
  dispatch(loading(true));
  try {
    const response = await api.get(`/recipe/${recipe_uuid}`);
    if (response.status === 200) {
      dispatch(recipe(response.data));
    }
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(loading());
  }
};

export const storeRecipes = (data) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const headers = {
      "Content-Type": `multipart/form-data`,
    };
    const response = await api.post(`/recipe`, data, { headers: headers });
    if (response.status === 201) {
      dispatch(success({status:true}));
    }
  } catch (err) {
    dispatch(
      error({
        status: true,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const updateRecipes = (data, recipe_id) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.post(`/recipe/${recipe_id}/update`, data);
    if (response.status === 200) {
      dispatch(success(true));
    }
  } catch (err) {
    dispatch(
      error({
        status: true,
        errors: err.response.data.errors,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};

export const deleteRecipes = (recipe_id) => async (dispatch) => {
  dispatch(btnLoading(true));
  try {
    const response = await api.delete(`/recipe/${recipe_id}`);
    if (response.status === 200) {
      dispatch(success(true));
    }
  } catch (err) {
    dispatch(
      error({
        status: true,
        message: err.response.data.message,
      })
    );
  } finally {
    dispatch(btnLoading());
  }
};
