import Users from '../app/users';
import Recipes from '../app/recipes';

const routes = [
	{
		path: 'users/*',
		element: <Users />
	},
	{
		path: 'recipes/*',
		element: <Recipes />
	}
];

export default routes;