export const Submit = ({ type, status, text, icon }) => {
  return status ? (
    <button
      className="bg-blue-500 text-white font-bold py-3 px-5 border-b-4 border-blue-700 rounded opacity-60 scale-90 cursor-not-allowed"
      disabled
    >
      <i className="fa-solid fa-spin fa-spinner mr-1" /> Loading...
    </button>
  ) : (
    <button className="bg-blue-500 text-white font-bold py-3 px-5 border-b-4 border-blue-700 rounded hover:opacity-70 active:scale-90">
      <i className={`${icon} mr-1`} />
      {text}
    </button>
  );
};
