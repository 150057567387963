export const TextField = ({
  type,
  id,
  value,
  className,
  onChange,
  error,
  label,
}) => {
  
  return (
    <div className="mb-4">
      {label && (
        <label
          className="w-full font-bold text-gray-400 font-sans"
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className="flex flex-col">
        <input
          id={id}
          name={id}
          type={type}
          defaultValue={value}
          className={`w-full border p-2 border-gray-400 focus:outline-none focus:ring-2`}
          onChange={onChange}
        />
        {error.status && error.errors[id] && (
          <span className="text-red-500 text-xs mt-1">{error.errors[id][0]}</span>
        )}
      </div>
    </div>
  );
};
