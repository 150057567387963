import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from "./app/comp/container";
import Home from "./app/pages/home";
import Login from "./app/auth/login";
import RequiredAuth from "./app/comp/required-auth";
import routes from "./utils/routes";
import { ViewCode, Instructions } from './app/instructions/instructions'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Container />}>
          <Route path="" element={<Home />} />
          <Route path="login" element={<Login />} />
          {routes.map((path, key) => (
            <Route
              key={key}
              path={path.path}
              element={<RequiredAuth>{path.element}</RequiredAuth>}
            />
          ))}
        </Route>
        <Route path="view-code/:code" element={<ViewCode />} />
        <Route path="view-image/:uuid" element={<Instructions />} />
        <Route path="instructions/:uuid" element={<Instructions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
