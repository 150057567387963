import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 30000,
  headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
	}
});

export default instance;